import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./useAuth";
import useOauthLogin from "./useOauth";
import { podioClientId } from "../utils/config";
import { podioAuth } from "../routes/BackendRoutes";
import { apiRequest } from "../services/apiRequest";

function useLogin() {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const { isLoggedIn, setLogin, setLogout } = useAuth();
    const [toastData, setToastData] = useState({ show: false, type: "", message: "" });

    const clientId = podioClientId;  // Use camelCase for variable names
    const redirectUri = `${window.origin}/login`;  // Consistent naming convention for variables

    const url = `https://podio.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}`;

    const handleOauthCallback = useCallback(async (query) => {
        setLoader(true);
        if (query.state === true) {
            try {
                const options = { data: { code: query.code }, method: "POST" };
                const res = await apiRequest(podioAuth, options);

                if (res.status === 200) {
                    setToastData({ show: true, type: "success", message: res.message });
                    setTimeout(() => {
                        setLogin(res);
                        setLoader(false);  // Ensure loader is stopped
                    }, 1000);
                } else {
                    setToastData({ show: true, type: "error", message: res.message });
                    setLogout();
                    setLoader(false);
                }
            } catch (error) {
                setToastData({
                    show: true,
                    type: "error",
                    message: "Something went wrong, please try again."
                });
                setLogout();
                setLoader(false);
            }
        } else {
            setToastData({
                show: true,
                type: "error",
                message: "Something went wrong, please try again.",
            });
            setLogout();
            setLoader(false);
        }
    }, [setLogin, setLogout]);

    const [login] = useOauthLogin(url, redirectUri, handleOauthCallback);

    useEffect(() => {
        setLogout();
        if (isLoggedIn) {
            navigate("/");  // Redirect to home once logged in
        }
    }, [isLoggedIn, navigate, setLogout]);  // Add `navigate` and `setLogout` to dependency array

    return {
        loader,
        login,
        toastData,
    };
}

export default useLogin;
