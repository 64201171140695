import React, { useEffect } from "react";
import "../../assets/styles/pages/SchedulerModal.scss";
import { formatDateTime, getDuration } from "../../utils/common";

const SchedulerModal = ({ isOpen, onClose, eventData }) => {
  const handleClickOutside = (event) => {
    if (event.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    // Add event listener when the modal opens
    if (isOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    // Clean up event listener when the modal closes
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  if (!isOpen) return null;
  const {
    text = "",
    department = [],
    address = {},
    start_date,
    end_date,
    company_name = "",
    item_id = "",
    sales_rep_details = [],
  } = eventData;
  const { formatted = "", city = "", postal_code = "" } = address;

  return (
    <div className={`modal-overlay ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        {eventData ? (
          <>
            <div className="modal-title">
              <button
                className="close-button"
                onClick={onClose}
                aria-label="Close Modal"
              >
                &times;
              </button>
              <h2>{text}</h2>
            </div>

            <div className="event-details">
              <p>
                <strong>Salgsrepræsentantens navn:</strong>{" "}
                {sales_rep_details
                  .map((rep) => rep.sales_representative_name)
                  .join(", ")}
              </p>
              <p>
                <strong>Afdeling:</strong>
                {department.length > 0 && department[0]}
              </p>
              <p>
                <strong>Mødeadresse:</strong>
                Adresse: {formatted}
                {city && (
                  <>
                    <br />
                    <span>By: {city}</span>{" "}
                  </>
                )}
                {postal_code && (
                  <>
                    <br />
                    <span>Postnummer: {postal_code}</span>
                  </>
                )}
              </p>
              <p>
                <strong>Tidsperiode:</strong>{" "}
                {formatDateTime(start_date, end_date)}
              </p>
              <p>
                <strong>Varighed:</strong> {getDuration(start_date, end_date)}
              </p>
              <p>
                <strong>Virksomhed/klient:</strong> {company_name}
              </p>
              <svg
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.60066 6.64792L2.83251 8.41607C2.17215 9.07642 1.7924 9.97494 1.79934 10.9192C1.80628 11.8636 2.17772 12.7676 2.86941 13.4379C3.5397 14.1296 4.44389 14.501 5.38805 14.5079C6.35374 14.515 7.23099 14.1567 7.89138 13.4963L9.65954 11.7282M11.918 9.51289L13.6862 7.74474C14.3465 7.08439 14.7263 6.18587 14.7193 5.24156C14.7124 4.29725 14.341 3.39326 13.6493 2.72293C12.9791 2.05279 12.0751 1.68133 11.1308 1.6744C10.1865 1.66746 9.28784 2.02565 8.62745 2.68602L6.8593 4.45417M5.57804 10.7144L10.8825 5.40996"
                  stroke="#007bff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <a
                href={`https://podio.com/ws/app/item/${item_id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Åbn i Podio
              </a>
            </div>
          </>
        ) : (
          <p>Ingen begivenhed valgt.</p>
        )}
      </div>
    </div>
  );
};

export default SchedulerModal;
