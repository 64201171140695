import { useState, useEffect } from 'react';
import { useAuthContext } from './useAuthContext';

export const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const { dispatch } = useAuthContext();


    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('isLoggedIn') === 'true';
        setIsLoggedIn(isLoggedIn);
    }, []);

    const setLogin = (data) => {
        localStorage.setItem("user", JSON.stringify(data.userDetails));
        localStorage.setItem("token", data.token);
        dispatch({
          type: "LOGIN",
          payload: data.userDetails,
          token: data.token,
        });
    };

    const setLogout = () => {
        sessionStorage.clear();
        setIsLoggedIn(false);

    };

    return { isLoggedIn, setLogin, setLogout };
};