import React from 'react';
import "../../assets/styles/pages/ConfirmationDialog.scss";
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';
import { AiOutlineClose } from 'react-icons/ai';

const ConfirmationDialog = ({ show, proceed, cancel, confirmation }) => {
    const handleKeyUp = (e) => {
        if (e.key === 'Escape') {
            cancel();
        }
    };
    if (!show) {
        return null;
    }

    return (
        <div className={`cd-popup ${show ? 'is-visible' : ''}`} role="alert" onClick={cancel} onKeyUp={handleKeyUp}>
            <div className="cd-popup-container" onClick={(e) => e.stopPropagation()}>
                <p>{confirmation}</p>
                <ul className="cd-buttons">
                    <li>
                        <button type="button" onClick={proceed}>Yes</button>
                    </li>
                    <li>
                        <button type="button" onClick={cancel}>No</button>
                    </li>
                </ul>
                <button className="cd-popup-close img-replace" onClick={cancel}>
                    <AiOutlineClose size={20} />
                </button>
            </div>
        </div>
    );
};

ConfirmationDialog.propTypes = {
    show: PropTypes.bool,
    proceed: PropTypes.func,
    cancel: PropTypes.func,
    confirmation: PropTypes.string,
    options: PropTypes.object
}

export default confirmable(ConfirmationDialog);
