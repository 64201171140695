import React, { useState, useEffect } from "react";

const DepartmentFilter = ({
  departments,
  setSelectedFilters,
  selectedFilters,
  isInitialLoadForFilter,
}) => {
  const [selectedOption, setSelectedOption] = useState([]);
  useEffect(() => {
    if (selectedFilters.departments.length > 0) {
      const presetOptions = departments.filter((department) =>
        selectedFilters.departments.includes(department.label)
      );
      setSelectedOption(presetOptions);
    }
  }, [departments, selectedFilters]);

  const handleButtonClick = (department) => {
    isInitialLoadForFilter.current = false;
    // Check if department is already selected
    const alreadySelected = selectedOption.some(
      (opt) => opt.value === department.value
    );

    let newSelected;
    if (alreadySelected) {
      // Remove it if already selected
      newSelected = selectedOption.filter((opt) => opt.value !== department.value);
    } else {
      // Add it if not selected
      newSelected = [...selectedOption, department];
    }

    setSelectedOption(newSelected);

    const selectedValues = newSelected.map((option) => option.label);
    setSelectedFilters((prevState) => ({
      ...prevState,
      departments: selectedValues,
    }));
  };

  return (
    <div>
      <div style={{ display: "flex", gap: "8px" }}>
        {departments.map((dept) => {
          const isActive = selectedOption.some((sel) => sel.value === dept.value);
          return (
            <button
              key={dept.value}
              onClick={() => handleButtonClick(dept)}
              style={{
                backgroundColor: isActive ? dept.color : "white",
                border: "1px solid #ccc",
                padding: "8px 12px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              {dept.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default DepartmentFilter;
