import { React, Suspense } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import Loading from "../components/compound/Loader.js";
import { useAuthContext } from "../hooks/useAuthContext.js";
import Login from "../pages/auth/Login.js";
import HomePage from "../pages/HomePage.js";
import NotFound from "../pages/NotFound";

const AppRoutes = () => {
  const { user } = useAuthContext();
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Routes>
          <Route
            path="/login"
            element={!user ? <Login /> : <Navigate to="/" />}
          />
          <Route
            path="/"
            element={user ? <HomePage /> : <Navigate to="/login" />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  );
};

export default AppRoutes;
