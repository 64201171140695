import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const NavigationControls = ({
  currentViewDate,
  handleViewChange,
  goPrev,
  goNext,
  defaultView,
}) => {
  return (
    <>
      <div className="navigation-controls">
        <div className="date-display-area">
          <span onClick={goPrev} className="nav-button" aria-label="Previous">
            <IoIosArrowBack />
          </span>
          <span>{currentViewDate}</span>
          <span onClick={goNext} className="nav-button" aria-label="Next">
            <IoIosArrowForward />
          </span>
        </div>
        <div className="view-selector">
          <button
            onClick={() => handleViewChange("day")}
            className={defaultView == "day" ? "active" : ""}
          >
            dag
          </button>
          <button
            onClick={() => handleViewChange("week")}
            className={defaultView == "week" ? "active" : ""}
          >
            uge
          </button>
          <button
            onClick={() => handleViewChange("month")}
            className={defaultView == "month" ? "active" : ""}
          >
            måned
          </button>
        </div>
      </div>
    </>
  );
};

export default NavigationControls;
