// SalesRepList.js
import React, { useState, useEffect } from "react";
import "../../assets/styles/pages/salesRepList.scss";
import { BACKEND_URL } from "../../utils/config";

const BASE_URL = BACKEND_URL;

const SalesRep = ({
  items,
  setSelectedFilters,
  selectedFilters,
  isInitialLoadForFilter,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (selectedFilters.salesRep.length > 0) {
      setSelectedItems(selectedFilters.salesRep);
    }
  }, [selectedFilters]);

  useEffect(() => {
    if (!isInitialLoadForFilter.current) {
      setSelectedFilters((prevState) => ({
        ...prevState,
        salesRep: [], // Use an array to store the IDs
      }));
      setSelectedItems([]);
    }
  }, [selectedFilters.departments]);

  const handleCheckboxChange = (itemId) => {
    // const updatedSelection = selectedItems.includes(itemId)
    //   ? selectedItems.filter((id) => id !== itemId)
    //   : [...selectedItems, itemId];
    // setSelectedItems(updatedSelection);
    // setSelectedFilters((prevState) => ({
    //   ...prevState,
    //   salesRep: updatedSelection, // Use an array to store the IDs
    // }));

    // updated to select only one sales rep at a time
    setSelectedFilters((prevState) => ({
      ...prevState,
      salesRep: [itemId],
    }));
  };

  const filteredItems = items.filter((item) => {
    if (
      !selectedFilters.departments ||
      selectedFilters.departments.length === 0
    ) {
      return true;
    }
    return selectedFilters.departments.includes(item.departments);
  });

  const handleClearFilter = () => {
    setSelectedFilters({
      departments: [],
      salesRep: [],
    });
    setSelectedItems([]);
  };

  return (
    <div className="multi-select-container">
      <ul className="multi-select-list">
        {filteredItems.map((item) => (
          <li key={item.item_id}>
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={selectedItems.includes(item.item_id)}
                onChange={() => handleCheckboxChange(item.item_id)}
              />
              <span className="checkmark"></span>
              <span className="label">{item.label}</span>
              {item.image && (
                <img
                  src={BASE_URL + item.image}
                  alt={item.label}
                  className="sales-rep-image"
                />
              )}
            </label>
          </li>
        ))}
      </ul>
      {selectedItems.length > 0 && (
        <div className="clear-btn">
          <button onClick={handleClearFilter}>Clear all</button>
        </div>
      )}
    </div>
  );
};

export default SalesRep;
