import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toaster({ type, message }) {
    // Using useCallback to memoize the notify function
    const notify = useCallback(() => {
        const options = {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        };

        if (type === 'success') {
            toast.success(message, options);
        } else if (type === 'error') {
            toast.error(message, options);
        }
    }, [type, message]);

    // Trigger notification when message or type changes
    useEffect(() => {
        if (message) {
            notify();
        }
    }, [message, notify]);

    return <ToastContainer />;
}

Toaster.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};

export default Toaster;
