import { useQuery } from "@tanstack/react-query";
import { apiRequest } from "../services/apiRequest";
import { useAuthContext } from "./useAuthContext";
import { getInitialDataRoute, getFiltersRoute } from "../routes/BackendRoutes"; // Assuming there is a route for filters
import { useState, useEffect } from "react";
import { Timezone } from "../utils/config";

// Function to fetch filters data
const fetchFilters = async (access_token) => {
  const options = {
    access_token,
    method: "GET",
  };
  const response = await apiRequest(getFiltersRoute, options);
  if (!response) {
    throw new Error("Failed to fetch filters");
  }
  return response.filterData;
};

// Modify the fetchDashboardData to accept start and end dates
const fetchDashboardData = async (access_token, startDate, endDate) => {
  const options = {
    access_token,
    method: "POST",
    data: {
      start: startDate,
      end: endDate,
      time_zone: Timezone,
    },
  };
  const response = await apiRequest(getInitialDataRoute, options);
  if (!response) {
    throw new Error("Failed to fetch data");
  }
  return response;
};

const useDashboard = () => {
  // const currentWeek = getCurrentWeek();
  const { access_token } = useAuthContext();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filters, setFilters] = useState(null);
  // Fetch filters on the initial load
  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    error: filtersError,
  } = useQuery({
    queryKey: ["filtersData", access_token],
    queryFn: () => fetchFilters(access_token),
    enabled: !!access_token, // Ensure the token is available before fetching
    onSuccess: (data) => setFilters(data), // Save filters to state once fetched
  });

  // Use the filters in the query key to trigger re-fetch when dates change
  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: ["dashboardData", access_token, startDate, endDate], // Add dates to the query key
    queryFn: () => fetchDashboardData(access_token, startDate, endDate),
    enabled: !!access_token && !!filters, // Ensure the token and filters are available before fetching
  });

  // A method to update the filters (startDate and endDate)
  const updateFilters = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  useEffect(() => {
    if (startDate && endDate) {
      refetch();
    }
  }, [startDate, endDate, refetch]);

  return {
    data: data?.data,
    filters: filtersData, // Return the fetched filters
    error,
    filtersError,
    isLoading: isLoading || isFiltersLoading, // Combine loading states
    isError: isError || !!filtersError, // Combine error states
    refetch,
    updateFilters,
    fetchDashboardData,
  };
};

export default useDashboard;
