import React from "react";
import "../../assets/styles/pages/login.scss";
import logo from "../../assets/images/logo.png";
import useLogin from "../../hooks/useLogin";
import Loading from "../../components/compound/Loader";
import Toaster from "../../components/compound/Toaster"; // Corrected typo

const LoginPage = () => {
  const { loader, login, toastData } = useLogin();

  return (
    <>
      {toastData.show && (
        <Toaster type={toastData.type} message={toastData.message} />
      )}
      {loader && <Loading />}
      <div className="login-container">
        <div className="login-box">
          <div className="image-wrapper">
            <img src={logo} alt="Web-Concept Logo" className="logo" />
          </div>
          <h2 className="login-title">Velkommen til salgsmøde kalenderen</h2>
          <p className="login-description">
            Log venligst ind med din Podio-konto for at få adgang til vores
            applikation.
          </p>
          <button className="login-button" onClick={login}>
            Log ind med Podio
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
