import Scheduler from "../components/scheduler";
function HomePage() {
  return (
    <>
      <Scheduler />
    </>
  );
}

export default HomePage;
