import React from "react";
import SalesRep from "../compound/SalesRepList";

const Sidebar = ({ filters, setSelectedFilters, selectedFilters, isInitialLoadForFilter }) => {
    return (
        <div className="sidebar">
            <SalesRep items={filters?.salesRep} setSelectedFilters={setSelectedFilters} selectedFilters={selectedFilters} isInitialLoadForFilter={isInitialLoadForFilter} />
        </div>
    );
};

export default Sidebar;
