
export const apiRequest = async (endpoint, options = {}) => {
  try {
    const headers = {
      "Content-Type": "application/json", 
      ...(options.access_token && { Authorization: `Bearer ${options.access_token}` }),
    };

    const response = await fetch(endpoint, {
      method: options.method ?? "get",
      headers,
      body: JSON.stringify(options.data),
    });

    if (response.status === 401 && options.access_token) {
      tokenExpire();
      throw new Error("Authentication failed: Unauthorized"); 
    }

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};
export const putWithToken = async (endpoint, options = {}) => {
  try {
    let data = null;
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${options.access_token}`,
      },
      body: JSON.stringify(options.data),
    });

    if (response.status === 401) {
      tokenExpire();
      throw new Error("Authentication failed: Unauthorized");
    } else if (response.ok) {
      data = await response.json();
    } else {
      throw new Error("Request failed with status:", response.status);
    }
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

export const postWithFile = async (endpoint, options = {}) => {
  try {
    let data = null;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${options.access_token}`,
      },
      body: options.data,
    });

    if (response.status === 401) {
      tokenExpire();
      throw new Error("Authentication failed: Unauthorized");
    } else if (response.ok) {
      data = await response.json();
    } else {
      throw new Error("Request failed with status:", response.status);
    }
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};

const tokenExpire = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  window.location.href = "/auth/login";
};
